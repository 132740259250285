import React from 'react';
import ears from '../assets/ears.png'
import moment from 'moment/moment';

function About() {
  // function age() {
    
  //   const actualAge = moment('20040310', 'YYYYMMDD').fromNow()
  //   // const date = 
  //   // const bday = 
  //   // if (date > bday) {
  //   //   actualAge = dateYear - bdayYear
  //   // } 
    
  // }


  return (
    <section id="about-me" className=" intro">
      <div className="w-full text-xl text-bold pb-5 item flex justify-center bg-blue-400">
          <h1 className="justify-center">About Me</h1>
        </div>
      <div className="flex justify-center m-5">
        <div className="intro-info w-3/4 flex item-center">
          <div className="intro-img">
            <img src=''></img>
          </div>
          <img className="px-5" src={ears}></img>
          <p>
          My name is Wesley, I am {moment('20040310', 'YYYYMMDD').fromNow("int")} old, and I am a web developer. I have always been passionate about web development and enjoy exploring new ways to create and innovate.
 	Throughout The University of Minnesota Fullstack Web Development bootcamp, I have gained extensive experience in developing websites, which has allowed me to create a diverse skill set that involves 21 technologies including JavaScript, TailwindCSS, MERN Stack, and others. I am committed to delivering high-quality work and always strive to exceed expectations.
	In my free time, I enjoy bowling, playing guitar, and playing video games, and web development which helps me to recharge and stay inspired. I also enjoy reading about my interests, which allows me to expand my knowledge and gain new perspectives.
	Thank you for taking the time to learn a little more about me. I hope that my portfolio showcases my passion, expertise, and commitment. If you have any questions or would like to discuss a potential project, please don’t hesitate to contact me.
            <br></br>
            
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;